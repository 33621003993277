@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.tabs {
  width: 208px; }

.tabs__select {
  margin-top: 10px;
  height: 100%;
  text-align: center;
  opacity: 0.5;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .tabs__card:hover .tabs__select {
    opacity: 1; }

.tabs__select-day {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase; }
