@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.card-details {
  width: 568px;
  height: 324px; }

.card-details__loader {
  height: 100%; }

.card-details__header {
  margin-bottom: 30px; }

.card-details__title {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 5px; }

.card-details__subtitle {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  color: #9b9b9b; }

.card-details__stats {
  width: 300px;
  line-height: 1.2; }

.card-details__stats-list {
  width: 66.67%; }

.card-details__stats-item {
  width: 50%;
  position: relative;
  padding-left: 14px; }
  .card-details__stats-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 85%;
    width: 4px;
    border-radius: 4px;
    background: linear-gradient(to bottom, #a8d7f6, #62c0ff); }
  .card-details__stats-item:nth-child(2)::before {
    background: linear-gradient(to bottom, #eef166, #f0d557); }

.card-details__stats-last-item {
  width: 33.33%; }

.stats-item__count {
  font-size: 28px;
  margin-right: 8px;
  line-height: 1; }

.stats-item__legend {
  font-size: 10px;
  display: inline-block;
  line-height: 1.3; }

.card-details__stats-graph {
  z-index: 1;
  position: relative;
  left: -35px; }

.card-details__time {
  position: absolute;
  top: 117px;
  height: 146px;
  font-size: 10px;
  font-weight: 600;
  width: 60px; }
  .card-details__time::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    border-left: 1px dotted white;
    left: 3px;
    top: 11px;
    animation: breatheOpacity 800ms linear infinite alternate; }

.card-details__time-point {
  width: 7px;
  height: 7px;
  background-color: #c3ec6c;
  box-shadow: 0 0 10px 0 white;
  border-radius: 50%;
  margin-right: 4px;
  animation: breatheShadow 800ms linear infinite alternate; }
