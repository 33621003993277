@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.visits-table__header {
  color: #9b9b9b;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px; }

.visits-table__group {
  height: 50px; }
  .visits-table__group > div {
    padding-right: 10px; }
    .visits-table__group > div:first-child {
      padding: 0 20px;
      width: 12%; }
    .visits-table__group > div:nth-child(2) {
      width: 20%; }
    .visits-table__group > div:nth-child(3) {
      width: 25%; }
    .visits-table__group > div:nth-child(4) {
      width: 25%; }
    .visits-table__group > div:nth-child(5) {
      width: 18%;
      padding-right: 20px; }

.visits-table__header-cell_sort {
  position: relative;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .visits-table__header-cell_sort::before, .visits-table__header-cell_sort::after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #689dbf;
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .visits-table__header-cell_sort::before {
    bottom: 0; }
  .visits-table__header-cell_sort::after {
    top: 0; }
  .visits-table__header-cell_sort_desc {
    color: white !important; }
    .visits-table__header-cell_sort_desc::before {
      opacity: 1; }
  .visits-table__header-cell_sort_asc {
    color: white !important; }
    .visits-table__header-cell_sort_asc::after {
      opacity: 1; }
  .visits-table__header-cell_sort:hover {
    background-color: rgba(104, 157, 191, 0.15); }

.visits-table__row {
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1; }
  .visits-table__row::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-image: linear-gradient(to bottom, #a8d7f6, #62c0ff);
    border-radius: 2px;
    opacity: 0;
    transform: scaleY(0);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1), transform 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .visits-table__row_selected {
    background-color: rgba(84, 120, 144, 0.5) !important;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
    .visits-table__row_selected::before {
      opacity: 1;
      transform: scaleY(1); }
  .visits-table__body-row-block:nth-child(odd) > .visits-table__row {
    background-color: rgba(255, 255, 255, 0.05); }
  .visits-table__body-row-block:nth-child(odd) > .visits-table__row:hover, .visits-table__row:hover {
    background-color: rgba(255, 255, 255, 0.1); }

.visits-table__cell {
  height: 100%;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .visits-table__cell_sort {
    background-color: rgba(104, 157, 191, 0.15); }

.visits-table__number {
  font-size: 12px;
  color: #9b9b9b; }

.visits-table__plate {
  font-weight: bold; }

.visits-table__plate-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #656769;
  margin-right: 15px; }
  .visits-table__row_selected .visits-table__plate-point:not(.visits-table__plate-point_site) {
    background-color: white; }
  .visits-table__plate-point_site {
    background-color: #c3ec6c; }

.visits-table__date-part {
  color: #9b9b9b; }

.visits-table__site-date {
  color: #c3ec6c; }

.visits-table__filter_asc {
  transform: rotate(180deg); }

.visits-table__cell-details-enter {
  height: 0;
  opacity: 0;
  margin-bottom: 0; }

.visits-table__cell-details-enter-active {
  height: 270px;
  opacity: 1;
  margin-bottom: 20px;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1); }

.visits-table__cell-details-exit {
  height: 270px;
  margin-bottom: 20px;
  opacity: 1; }

.visits-table__cell-details-exit-active {
  height: 0;
  opacity: 0;
  margin-bottom: 0;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1); }
