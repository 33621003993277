.doughnut {
  position: relative;
  margin-right: 10px; }

.doughnut__count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  font-weight: 600; }

.doughnut-legend {
  font-size: 10px;
  display: inline-block;
  line-height: 1.3; }
