@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.card-wrapper {
  position: relative; }
  .card-wrapper .recharts-wrapper {
    cursor: pointer !important; }

.card_selected {
  background: linear-gradient(135deg, rgba(84, 120, 144, 0.5), rgba(20, 29, 41, 0.5)); }

.card__content {
  height: 100%;
  opacity: 0.5;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .card_selected .card__content {
    opacity: 1; }
  .card:hover .card__content {
    opacity: 1; }

.card__date {
  margin-right: 15px;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  line-height: 1; }

.card__date-text {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal; }
  .card__date-text_month {
    color: #9b9b9b; }

.card__stats {
  width: 100%; }

.card__graph {
  margin-bottom: 10px; }

.card__stats-item {
  line-height: 0.6;
  color: #7bb9e3;
  font-size: 13px; }
  .card__stats-item:nth-child(2) {
    color: #f1e76f; }
  .card__stats-item:last-child {
    color: #c3ec6c; }

.card__stats-icon {
  margin-right: 3px; }

.card__border {
  width: 4px;
  height: 100%;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(to bottom, #a8d7f6, #62c0ff);
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1), transform 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .card__border_active {
    opacity: 1;
    transform: scaleY(1); }
