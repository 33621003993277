.header {
  padding: 20px 0; }

.header__logo {
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase; }

.header__logo-image {
  margin-right: 8px; }
