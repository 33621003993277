.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000; }
  .loader_block {
    position: absolute;
    background-color: transparent;
    z-index: 1; }
