@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.live-header {
  padding-top: 23px;
  margin-bottom: 20px; }

.live-header__block {
  min-height: 44px; }

.live-header__label {
  position: absolute;
  text-align: center;
  width: 160px;
  top: 18px;
  left: -4px; }

.live-header__time {
  font-size: 32px;
  font-weight: 300;
  color: #c3ec6c;
  line-height: 1; }

.live-header__time-part {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: -5px; }

.live-header__stats {
  line-height: 1.2; }
