@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.modal {
  position: relative;
  padding: 60px;
  background-color: #121516; }

.modal__header {
  margin-bottom: 40px; }

.modal__title {
  font-size: 32px;
  font-weight: 300; }

.modal__subtitle {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9b9b9b;
  margin-bottom: 5px; }
  .modal__subtitle .modal__number-mismatch {
    color: white; }

.modal__graphs {
  position: relative;
  width: 587px; }

.modal__line {
  overflow: hidden;
  padding-top: 25px;
  width: 100%;
  text-align: center; }

.modal__line-thread {
  position: relative;
  border-bottom: 1px dashed #656769;
  margin-bottom: 8px; }

.modal__line-range {
  height: 6px;
  border-radius: 4px;
  background-color: #c3ec6c;
  width: 34px;
  position: absolute;
  top: -3px; }

.modal__line-time {
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  color: white;
  top: 8px;
  width: 60px; }

.modal__line-disc {
  position: relative;
  width: 7px;
  height: 7px;
  background-color: white;
  border-radius: 50%;
  margin-right: 4px; }
  .modal__line-disc::before {
    content: '';
    display: block;
    position: absolute;
    height: 13px;
    border-left: 1px dotted white;
    left: 3px;
    top: 7px; }
  .modal__line-time_reverse > .modal__line-disc {
    order: 1; }

.modal__line-text {
  margin-right: 3px; }

.modal__line-hour {
  text-align: right;
  width: 12.5%;
  font-size: 10px;
  font-weight: 600;
  color: #9b9b9b; }

.modal__pie {
  margin-left: 47px; }

.modal__block {
  margin-bottom: 25px; }

.modal__card {
  position: relative;
  opacity: 0.8;
  background-color: rgba(255, 255, 255, 0.05) !important;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .modal__card::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-image: linear-gradient(to bottom, #a8d7f6, #62c0ff);
    border-radius: 2px;
    opacity: 0;
    transform: scaleY(0);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1), transform 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .modal__card:hover {
    opacity: 1; }
  .modal__card_selected {
    opacity: 1;
    background: linear-gradient(135deg, rgba(84, 120, 144, 0.5), rgba(20, 29, 41, 0.5)); }
    .modal__card_selected::before {
      opacity: 1;
      transform: scaleY(1); }

.modal__card-text {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase; }

.modal__image {
  border-radius: 2px;
  width: 587px;
  height: 421px;
  object-fit: cover; }

.modal__image-loader {
  border-radius: 2px;
  width: 587px;
  height: 421px;
  background: center/cover no-repeat rgba(255, 255, 255, 0.05); }
