@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.live-list__caption {
  font-size: 12px;
  color: #9b9b9b;
  height: 40px; }

.live-list__caption-header {
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 1px; }
  .live-list__caption-header:first-child {
    width: 30%;
    text-align: left; }
  .live-list__caption-header:nth-child(2) {
    width: 43%; }
  .live-list__caption-header:nth-child(3) {
    width: 27%; }

.live-list__item {
  height: 40px; }
  .live-list__item:not(:last-child) {
    border-bottom: 1px solid #656769; }

.live-list__item-plate {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  width: 30%; }

.live-list__item-index {
  font-size: 10px;
  color: #9b9b9b;
  width: 20px;
  font-weight: normal; }

.live-list__item-time {
  width: 43%;
  text-align: right; }

.live-list__item-time-part {
  color: #9b9b9b; }

.live-list__item-duration {
  width: 27%;
  text-align: right; }

.live-list__overnight {
  margin: 10px 0; }

.live-list__overnight-text {
  margin: 0 8px;
  font-size: 10px;
  color: #f1e76f; }

.live-list__overnight-thread {
  width: 100%;
  border-bottom: 1px dashed #f1e76f; }
