@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.f {
  display: flex; }

.f-i {
  display: inline-flex; }

.fd-c {
  flex-direction: column; }

.fd-rr {
  flex-direction: row-reverse; }

.ai-c {
  align-items: center; }

.ai-fs {
  align-items: flex-start; }

.ai-fe {
  align-items: flex-end; }

.as-fs {
  align-self: flex-start; }

.as-c {
  align-self: center; }

.as-fe {
  align-self: flex-end; }

.jc-c {
  justify-content: center; }

.jc-sb {
  justify-content: space-between; }

.jc-sa {
  justify-content: space-around; }

.jc-fe {
  justify-content: flex-end; }

.jc-s {
  justify-content: stretch; }

.fw-w {
  flex-wrap: wrap; }

.fs-0 {
  flex-shrink: 0; }

.fb-50 {
  flex-basis: 50%; }

.fg-1 {
  flex-grow: 1; }

.fg-2 {
  flex-grow: 2; }

#root {
  min-width: 1200px; }

.container {
  position: relative;
  padding: 30px 25px; }
  .container_small {
    padding: 20px 25px 25px 25px; }

.version {
  position: fixed;
  left: 5px;
  bottom: 5px;
  color: #656769; }

.label {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 2px 7px;
  border-radius: 4px;
  background-color: #c3ec6c;
  color: #212527;
  line-height: 1.4;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12); }
  .label_small-radius {
    border-radius: 2px; }

.empty-data {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin: 10px 0; }

.error {
  margin: 5px 0;
  text-align: center;
  color: #f47771; }

.DayPicker {
  width: 208px !important;
  padding-top: 10px;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(84, 120, 144, 0.5), rgba(20, 29, 41, 0.5)) transparent; }
  .DayPicker::before {
    content: '';
    display: block;
    border-radius: 2px;
    position: absolute;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, #a8d7f6, #62c0ff);
    width: 100%;
    height: 4px; }
  .DayPicker .DayPicker_focusRegion {
    position: relative;
    left: -9px; }
  .DayPicker .DayPicker_weekHeaders__horizontal {
    margin: 0; }
  .DayPicker .DayPickerNavigation_button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background: transparent;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 0;
    top: 24px;
    border: none;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1); }
    .DayPicker .DayPickerNavigation_button:hover {
      background-color: rgba(255, 255, 255, 0.1); }
  .DayPicker .DayPickerNavigation_svg__horizontal {
    fill: white;
    width: 20px;
    margin: 0 !important; }
  .DayPicker .CalendarMonth_caption > strong {
    font-weight: normal !important;
    font-size: 10px;
    letter-spacing: 1px;
    color: white;
    text-transform: uppercase; }
  .DayPicker .DayPicker_weekHeader_li {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px; }
  .DayPicker .CalendarMonth_table {
    border-collapse: separate; }
  .DayPicker .CalendarMonthGrid,
  .DayPicker .CalendarMonth {
    background: transparent; }
  .DayPicker .CalendarDay {
    background: transparent;
    color: white;
    font-size: 12px;
    font-weight: normal;
    border-radius: 2px;
    border: none;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
  .DayPicker .CalendarDay__selected {
    background: #62c0ff;
    color: #212527;
    font-weight: 600; }
  .DayPicker .CalendarDay__default:hover:not(.CalendarDay__blocked_out_of_range) {
    background: rgba(98, 192, 255, 0.6);
    font-weight: 600;
    border: none;
    color: #212527; }
  .DayPicker .CalendarDay__today {
    background: rgba(255, 255, 255, 0.2); }
  .DayPicker .CalendarDay__outside {
    opacity: 0.5; }
  .DayPicker .CalendarDay__blocked_out_of_range {
    opacity: 0.2; }
  .DayPicker .CalendarDay__hovered_span,
  .DayPicker .CalendarDay__hovered_span:hover {
    background: rgba(255, 255, 0, 0.2);
    color: rgba(255, 255, 255, 0.5);
    border: none; }
  .DayPicker .CalendarDay__selected_span {
    background: rgba(255, 255, 0, 0.3);
    color: rgba(255, 255, 255, 0.5);
    border: none; }
  .DayPicker .CalendarDay__selected_span:hover {
    background: rgba(255, 255, 0, 0.5);
    color: rgba(255, 255, 255, 0.5);
    border: none; }
  .DayPicker .CalendarDay__blocked_calendar,
  .DayPicker .CalendarDay__blocked_calendar:hover {
    opacity: 0.3;
    background: transparent; }
  .DayPicker .DayPickerKeyboardShortcuts_buttonReset {
    display: none; }

body {
  font-family: "SourceSansPro", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: white; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  line-height: 1.3;
  margin: 0; }

ul, p, figure {
  margin: 0; }

a {
  user-select: none;
  text-decoration: none;
  cursor: pointer; }

ul {
  padding: 0;
  list-style: none; }

address {
  font-style: normal; }
