@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.filter {
  margin-bottom: 30px; }

.filter__title {
  font-size: 18px;
  font-weight: normal; }

.filter__title-date {
  color: #656769;
  margin-left: 10px; }
