@keyframes breatheOpacity {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 0.9; } }

@keyframes breatheShadow {
  0% {
    box-shadow: 0 0 5px 0 white; }
  100% {
    box-shadow: 0 0 20px 0 white; } }

.cell-details {
  overflow: hidden;
  margin-bottom: 20px; }

.cell-details__container {
  height: 270px;
  background-color: #121516; }

.cell-details__time {
  font-size: 12px;
  letter-spacing: 1px;
  color: #9b9b9b;
  text-transform: uppercase; }

.cell-details__image-block {
  transition: filter 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .cell-details__image-block:hover {
    filter: brightness(110%); }

.cell-details__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05); }
  .cell-details__image_hidden {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }

.cell-details__info {
  width: 100%; }

.cell-details__block {
  margin-bottom: 15px; }

.cell-details__number-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 1px;
  text-transform: uppercase; }

.cell-details__plate {
  margin-bottom: 10px; }

.cell-details__number {
  font-size: 24px;
  line-height: 1.1;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .cell-details__link:hover > .cell-details__number {
    color: #8dcffb; }
  .cell-details__number_duplicate {
    color: #9b9b9b; }

.cell-details__subtext {
  font-size: 10px;
  line-height: 1; }

.cell-details__zoom {
  opacity: 0.8;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) !important; }
  .cell-details__zoom:hover {
    opacity: 1; }
